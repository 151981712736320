import { Button, Dialog, DialogTitle } from "@bakkt/bakkt-ui-components";
import { Alert, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, LoaderFunctionArgs, defer, useLoaderData, useLocation, useFetcher } from "react-router-dom";
import { RootContextType, useRootContext } from "../../../RootLayout";
import { formatCurrentDate, shouldUseMockData } from "../../../utils/dataUtils";
import { fetchMockDataPromiseWithDelay, walletTransactions } from "../../../services/mockData";
import { WalletTransactionService } from "../../../services/serviceLoader";
import { PolicyActionDetails, WalletTransaction } from "../../../services/openAPI/client";
import { isApprover, isPolicyDisabled } from "../../../utils/permissionsUtil";
import ReviewSettlementDetails from "./ReviewSettlementDetails";

export interface SettlementFormData {
  accountId: number;
  organizationId: number;
  clientName: string;
  orgName: string;
  requesterName: string;
  createdOn: number;
  settlementTransactions: WalletTransaction[];
}

const ReviewSettlement = () => {
  const { selectedOrg, userInfo, addAlert } = useRootContext() as RootContextType;
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { state } = useLocation();
  const isApproved = state.approved;
  const policyItem = isApproved ? state.policyItem : state.data;
  const [open, setOpen] = useState(true);

  const { orgWalletTransactions } = useLoaderData() as {
    orgWalletTransactions: WalletTransaction[];
  };

  function getSettlementTransactions(policyItem: PolicyActionDetails) {
    const settlementTransactions = orgWalletTransactions.filter(
      (tx) =>
        JSON.parse(String(policyItem?.policyInstancePayload))?.transactionIds?.includes(tx.walletTransactionId || 0),
    );
    return settlementTransactions;
  }

  const settlementFormData: SettlementFormData = {
    accountId: userInfo.accountId,
    organizationId: selectedOrg.id,
    createdOn: policyItem.timestamp,
    clientName: policyItem.client,
    orgName: policyItem.organization,
    requesterName: policyItem.requester,
    settlementTransactions: getSettlementTransactions(policyItem),
  };

  const isDisabled = isPolicyDisabled(
    policyItem.approvalCount,
    policyItem?.consensusNumber,
    policyItem.policyInstanceStatus,
    policyItem.policySequenceStatus,
    isApproved,
  );

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleAcknowledge = () => {
    // fetcher.submit(JSON.stringify("acknowledge"), {
    //   method: "post",
    //   encType: "application/json",
    // });
    addAlert({
      severity: "success",
      messageHeader: "Settlement has been acknowledged.",
    });
    navigate(`/${selectedOrg.id}`);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={true}>
        <DialogTitle title={`Acknowledge Settlement`} severity="warning">
          Acknowledge your settlement details below.
        </DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant={"body2"} sx={{ mb: 2 }}>
              Settlement will occur within a 2 hr window of <strong>8:30am UTC on {formatCurrentDate()}</strong>. Please
              contact Deribit support regarding any settlement disputes.
            </Typography>
            <Typography variant="body2">
              Please note that the settlement quantity can be subject to change, and isn’t finalized until the
              transaction occurs on chain.
            </Typography>
          </Alert>
          <ReviewSettlementDetails settlementFormData={settlementFormData} />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"flex-end"}>
            <Button variant={"contained"} onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>

            {isApprover(userInfo, Number(selectedOrg.id)) && (
              <Button variant={"contained"} onClick={handleAcknowledge} disabled={isDisabled} autoFocus>
                Acknowledge Settlement
              </Button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewSettlement;

export async function loader({ params }: LoaderFunctionArgs) {
  const orgId = Number(params.organizationId);

  const allWalletTransactionsForOrg = shouldUseMockData
    ? await fetchMockDataPromiseWithDelay(walletTransactions, 500)
    : await WalletTransactionService.getWalletTransactions(undefined, orgId);

  return defer({
    orgWalletTransactions: allWalletTransactionsForOrg,
  });
}
