import { Alert, Button, DialogTitle, Divider, Typography } from "@bakkt/bakkt-ui-components";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import SelectCollateralAction from "./SelectCollateralAction";
import SelectCollateralSource from "./SelectCollateralSource";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CollateralMinimal, ManageCollateralContext } from "./CollateralContext";
import ReviewCollateralDetails from "./ReviewCollateralDetails";
import { collateralActionType } from "../../../utils/customTypes";
import ERC20Banner from "../../../components/banners/ERC20Banner";
import { isTrustWallet } from "../../../utils/dataUtils";
import { MIN_GAS_BALANCE } from "../../../utils/constants";
import { Wallet } from "../../../services/openAPI/client";

interface ManageCollateralModalProps {
  isERC20: boolean;
  gasBalance: number;
  feeWallet: Wallet | null;
}

const ManageCollateralModal = ({ isERC20, gasBalance, feeWallet }: ManageCollateralModalProps) => {
  const context = useContext(ManageCollateralContext);
  const {
    selectedWallet,
    collateral,
    collateralStep,
    collateralAction,
    handleReviewCollateralRequest,
    handleCreateCollateralRequest,
    offExchangeWallet,
  } = context;
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const disableCollateralRequest = () => {
    const disableCollateralCritera = [
      Number(collateral?.quantity) > Number(selectedWallet?.quantity),
      Boolean(collateral?.quantity) === false,
      Number(offExchangeWallet?.lockedBalance) - Number(collateral?.quantity) < 0 &&
        collateral?.action === collateralActionType.remove,
    ];
    return disableCollateralCritera.includes(true) ? true : false;
  };

  const isEnoughGasBalance = () => {
    return isTrustWallet(selectedWallet as Wallet) && isERC20 && gasBalance < MIN_GAS_BALANCE ? false : true;
  };

  const isCollateralRequestDisabled = disableCollateralRequest();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth={false}>
      {collateralStep == 1 && (
        <>
          <DialogTitle title="Manage Collateral" severity="secondary">
            Add collateral to or remove collateral from your Bakkt Off Exchange collateral balance.
          </DialogTitle>
          <DialogContent>
            <Alert severity="info" sx={{ mb: 2 }}>
              <Typography variant={"body2"} sx={{ mb: 2 }}>
                Collateral changes are processed as on-chain transactions at the time the request is fully completed
                after receiving full client consensus and Bakkt Support approval.{" "}
              </Typography>

              <Typography variant={"body2"}>
                In the event of an insufficient collateral balance when a collateral transaction is completed, the
                maximum available collateral amount will be applied.
              </Typography>
            </Alert>
            {isTrustWallet(selectedWallet as Wallet) && isERC20 && gasBalance < MIN_GAS_BALANCE && (
              <ERC20Banner gasBalance={gasBalance} feeWallet={feeWallet} />
            )}
            <SelectCollateralAction />
            <Divider sx={{ my: 3 }} />
            {collateralAction && <SelectCollateralSource />}
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button
              variant={"contained"}
              onClick={handleReviewCollateralRequest}
              autoFocus
              disabled={isCollateralRequestDisabled && isEnoughGasBalance()}
            >
              Review Collateral Change
            </Button>
          </DialogActions>
        </>
      )}
      {collateralStep == 2 && (
        <>
          <DialogTitle title="Review Collateral Change" severity="warning">
            Review your collateral change details below.{" "}
          </DialogTitle>
          <DialogContent>
            <ReviewCollateralDetails collateralRequest={collateral as Partial<CollateralMinimal>} />
          </DialogContent>
          <DialogActions>
            <Button variant={"contained"} onClick={handleClose} autoFocus>
              Close
            </Button>
            <Button variant={"contained"} onClick={handleCreateCollateralRequest} autoFocus>
              Create Collateral Change Request
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ManageCollateralModal;
